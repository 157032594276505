import React from 'react';
import { makeStyles } from '@material-ui/core';
import { PageProps, graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Layout, Header, Section, Helmet } from '@/components';
import { ServiceGroup } from '@/components/services';
import { IHeader, IService } from '@/types';

const useStyles = makeStyles((theme) => ({
  serviceGroup: {
    padding: theme.spacing(4, 0),
    zIndex: 0,
  },
}));

const Services: React.FC<PageProps> = ({ data }) => {
  const { t } = useTranslation();
  const header = data.page.children[0] as IHeader;
  const classes = useStyles();
  const services = data.page.children.slice(1) as IService[];

  return (
    <main>
      <Helmet page="Services" />
      <Layout>
        <Header
          title={t(header.title)}
          subtitle={t(header.subtitle)}
          images={[header.image]}
        />
        {services.map(
          ({ title, description, image, children: subServices }, i: number) => (
            <Section key={title} className={classes.serviceGroup}>
              <ServiceGroup
                title={t(title)}
                description={t(description)}
                image={image}
                subServices={subServices}
                swap={i % 2 === 1}
              />
            </Section>
          ),
        )}
      </Layout>
    </main>
  );
};

export default Services;

export const pageQuery = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    page(id: { eq: "Service" }) {
      children {
        ... on Header {
          id
          title
          image {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        }
        ... on Service {
          id
          title
          description
          children {
            ... on Service {
              id
              title
              description
            }
          }
          image {
            childImageSharp {
              gatsbyImageData(placeholder: TRACED_SVG)
            }
          }
        }
      }
    }
  }
`;
