import React from 'react';
import {
  makeStyles,
  Box,
  Divider,
  List,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import ImageContent from '@/components/ImageContent';
import { IService } from '@/types';

interface SubServices {
  services: IService[];
}

const useStyles = makeStyles((theme) => ({
  serviceBox: {
    padding: theme.spacing(1, 0),
  },
}));

const SubServices: React.FC<SubServices> = ({ services }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <List>
      {services.map(({ title }) => (
        <Box className={classes.serviceBox}>
          <ListItemText
            primary={t(title)}
            primaryTypographyProps={{ variant: `h6` }}
          />
          <Divider />
        </Box>
      ))}
    </List>
  );
};

interface ServiceGroup extends IService {
  swap: boolean;
  subServices: IService[];
}

const ServiceGroup: React.FC<ServiceGroup> = ({
  title,
  description,
  subServices,
  swap,
  image,
}) => (
  <ImageContent image={image} imageAlt={title} minHeight={90} swap={swap}>
    <Typography gutterBottom variant="h2">
      {title}
    </Typography>
    <Typography variant="body1">{description}</Typography>
    <SubServices services={subServices} />
  </ImageContent>
);

export default ServiceGroup;
