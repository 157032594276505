import React from 'react';
import { makeStyles, Box, useMediaQuery, useTheme } from '@material-ui/core';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: `center`,
    color: theme.palette.primary.dark,
    marginBottom: theme.spacing(2),
  },
  bodyText: {
    maxWidth: theme.spacing(60),
    margin: `0 auto`,
  },
  image: {
    position: `relative`,
    width: `100%`,
    minHeight: theme.spacing(40),
    height: `100%`,
    [theme.breakpoints.down(`sm`)]: {
      maxHeight: theme.spacing(50),
    },
  },
  content: {
    background: `white`,
    padding: theme.spacing(5),
    [theme.breakpoints.down(`sm`)]: {
      height: `100%`,
    },
    [theme.breakpoints.down(`xs`)]: {
      padding: theme.spacing(3),
    },
  },
  grid: {
    display: `grid`,
    maxWidth: theme.breakpoints.width(`md`),
    minHeight: ({ minHeight }: StyleProps) => theme.spacing(minHeight),
    margin: `0 auto`,
    width: `100%`,
    padding: 0,
  },
  gridItemImage: {
    gridColumn: ({ swap, isSmall }: StyleProps) => {
      if (isSmall) return 1;
      return !swap ? `1 / 9` : `2 / 10`;
    },
    gridRow: 1,
    height: `100%`,
    [theme.breakpoints.down(`sm`)]: {
      gridRow: 1,
    },
  },
  gridItemText: {
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    gridColumn: ({ swap, isSmall }: StyleProps) => {
      if (isSmall) return 1;
      return !swap ? `7 / 10` : `1 / 3`;
    },
    gridRow: 1,
    height: `100%`,
    zIndex: 1,
    [theme.breakpoints.down(`sm`)]: {
      padding: theme.spacing(3),
      paddingBottom: 0,
      opacity: 1,
      gridRow: 2,
    },
    [theme.breakpoints.down(`xs`)]: {
      paddingTop: theme.spacing(3),
    },
  },
}));

interface StyleProps {
  minHeight?: number;
  swap?: boolean;
  isSmall: boolean;
}
interface ImageContentProps {
  image: IGatsbyImageData;
  imageAlt: string;
  minHeight?: number;
  swap?: boolean;
  children: React.ReactNode;
}

const ImageContent: React.FC<ImageContentProps> = ({
  image,
  imageAlt,
  minHeight,
  swap,
  children,
}) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down(`sm`));
  const classes = useStyles({ minHeight, swap, isSmall });
  return (
    <Box className={classes.grid}>
      <Box
        className={classes.gridItemImage}
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
        width="100%"
      >
        <GatsbyImage
          className={classes.image}
          image={getImage(image)}
          alt={imageAlt}
        />
      </Box>
      <Box className={classes.gridItemText}>
        <Box className={classes.content}>{children}</Box>
      </Box>
    </Box>
  );
};

export default ImageContent;
